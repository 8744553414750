//import * as bootstrap from "bootstrap";
//import Swiper from 'swiper';

// or, specify which plugins you need:
import { Dropdown, Offcanvas, Button, Modal, Collapse } from 'bootstrap';
import Swiper, { Navigation, Pagination } from 'swiper';
import { CountUp } from "countUp.js";

// jQuery to add state classes to navbar
jQuery("body").on("mouseenter mouseleave", ".dropdown", (e) => {
  //var thisHeight = $(this).find('ul').outerHeight();
  //$('.mega-menu-wrapper > .container').height(thisHeight);
  let dropdown = jQuery(e.target).closest(".dropdown");
  let menu = jQuery(".dropdown-menu", dropdown);
  dropdown.addClass("show");
  menu.addClass("show");

  setTimeout(() => {
    dropdown[dropdown.is(":hover") ? "addClass" : "removeClass"]("show");
    menu[dropdown.is(":hover") ? "addClass" : "removeClass"]("show");
  }, 300);
});

let sliderSelector = ".swiper-container",
  defaultOptions = {
    slidesPerView: 1,
    autoHeight: true,
    observer: true,
  };

jQuery(() => {
  let nSlider = document.querySelectorAll(sliderSelector);

  [].forEach.call(nSlider, (slider, index, arr) => {
    let data = slider.getAttribute("data-swiper") || null;
    let dataOptions = {};

    if (data) {
      dataOptions = JSON.parse(data);
    }

    slider.options = Object.assign({}, defaultOptions, dataOptions);

    const swiper = new Swiper(slider, slider.options);

    /* stop on hover */
    if (
      typeof slider.options.autoplay !== "undefined" &&
      slider.options.autoplay !== false
    ) {
      slider.addEventListener("mouseenter", (e) => {
        swiper.autoplay.stop();
        console.log("stop");
      });

      slider.addEventListener("mouseleave", (e) => {
        swiper.autoplay.start();
        console.log("start");
      });
    }
  });

  let nCounter = document.querySelectorAll(".countup");

  [].forEach.call(nCounter, (counter, index, arr) => {
    var text = counter.innerText || counter.textContent;
    let countTo = parseInt(text.replace(",", ""), 10);
    var countUp = new CountUp(counter, countTo, { enableScrollSpy: true });
    //countUp.start();
    countUp.handleScroll();
  });

  jQuery(".offcanvas li.nav-item button").on("click", function () {
    let parent = jQuery(this).parent();
    parent.toggleClass("shown");
  });
});
